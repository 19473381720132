<template>
  <v-data-table
    :items="dataHosts"
    :headers="headers"
    item-key="id"
    :items-per-page="1000"
    hide-default-footer
    :loading="loading"
  >
    <template #item.name="{ item }">
      <router-link
        :to="{
          name: 'Usage data host detail',
          params: { dataHostId: item.id },
        }"
        >{{ item.name }}
      </router-link>
    </template>
    <template #item.lastAudit.finish_date="{ item }">
      <IsoDate :date="item.lastAudit.finish_date" v-if="item.lastAudit" />
    </template>
    <template #item.upcomingAudit.start_date="{ item }">
      <IsoDate
        :date="item.upcomingAudit.start_date"
        v-if="item.upcomingAudit"
      />
    </template>
    <template #item.audit_status="{ item }">
      <AuditStatus :status="item.audit_status" />
    </template>
  </v-data-table>
</template>

<script>
import CounterAPI from "../lib/api";
import IsoDate from "./IsoDate";
import AuditStatus from "./AuditStatus";

export default {
  name: "UsageDataHostList",
  components: { AuditStatus, IsoDate },
  data() {
    return {
      dataHosts: [],
      loading: false,
      api: new CounterAPI(process.env.COUNTER_API_ROOT),
    };
  },

  computed: {
    headers() {
      let ret = [
        { text: this.$t("label.dataHost.abbrev"), value: "abbrev" },
        { text: this.$t("label.dataHost.name"), value: "name" },
        {
          text: this.$t("label.dataHost.platformCount"),
          value: "platformCount",
          align: "right",
        },
        {
          text: this.$t("label.info.lastAudit"),
          value: "lastAudit.finish_date",
          align: "right",
        },
        {
          text: this.$t("label.info.upcomingAudit"),
          value: "upcomingAudit.start_date",
          align: "right",
        },
        {
          text: this.$t("label.info.auditStatus"),
          value: "audit_status",
        },
      ];
      return ret;
    },
  },

  methods: {
    async getUsageDataHosts() {
      this.loading = true;
      try {
        this.dataHosts = await this.api.getUsageDataHosts();
        this.dataHosts.forEach((dh) => this.postprocessDataHost(dh));
      } finally {
        this.loading = false;
      }
    },
    postprocessDataHost(dataHost) {
      dataHost.platformCount = dataHost.platforms.length;
      this.api.dataHostProcessAuditData(dataHost);
    },
  },

  mounted() {
    this.getUsageDataHosts();
  },
};
</script>

<style scoped></style>
