<template>
  <v-container
    fluid
    class="my-md-12 px-md-6 mx-xl-auto"
    style="max-width: 1600px"
  >
    <v-row>
      <v-col>
        <h2 class="text-md-h4 text--secondary">
          {{ $t("title.usageDataHosts") }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Usage data hosts are services which collect and publish usage data for
          individual publishers. They may be run by the publisher itself or by a
          third party service.
        </p>
        <v-alert type="info" color="primary" outlined>
          If you are only interested in harvesting your usage data using SUSHI,
          this data will probably not be of interest for you. You are more
          likely to find relevant data on the
          <v-btn :to="{ name: 'Home' }" color="primary" class="mx-1">{{
            $vuetify.breakpoint.smAndDown
              ? $t("title.platforms-short")
              : $t("title.platforms")
          }}</v-btn>
          page.
        </v-alert>
        <p>
          COUNTER compliance audits are done at the level of individual usage
          data hosts, which is why you will find most audit related data here.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <UsageDataHostList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsageDataHostList from "../components/UsageDataHostList";
export default {
  name: "UsageDataHostsPage",
  components: { UsageDataHostList },
};
</script>

<style scoped></style>
